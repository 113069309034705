<style scoped>
    @import "../../assets/css/mobile-tablet/imageHoverMenu.scss";
</style>
<template>
  <div class="imageHoverMenu--container">
    <h2>OUVREZ LES YEUX</h2>
    <nav class="imageHoverMenu--menu">
			<router-link
         class="imageHoverMenu--menu__item"
         v-for="menuItem in menuItemProjects"
         :key="menuItem.id"
         :to="{ name:'project.show',
                params:{
                        id:menuItem.id,
                        slug: menuItem.slug
                        }
                }">
				<span class="imageHoverMenu--menu__item-text">
					<span class="imageHoverMenu--menu__item-textinner">{{menuItem.name}}</span>
				</span>
				<span class="imageHoverMenu--menu__item-sub">{{menuItem.location}}</span>
			</router-link>
		</nav>
  </div>
</template>
<script scoped>
import imageHoverMenu  from "@/assets/js/mobile-tablet/imageHoverMenu/index.js";
import sourceData from "@/data/image_hover_menu.json";

// Hover images are set in the javascript ==> .\src\assets\js\mobile-tablet\imageHoverMenu\menuItem.js
export default {
  name: "ImageHoverMenu",
  components: {

  },
  // On définit la prop id de type string et obligatoire pour le fonctionnement de la route
  props: {},
  computed: {
    menuItemProjects() {
      // console.log(sourceData.projects);
      return sourceData.menuItemProjects;
    },
  },
  methods: {
    getImgUrlImageProjects: function (projectName, imagePath) {
        return require('@/assets/img/projects/' + projectName + '/' + imagePath);
    },
  },
  mounted: function () {
    imageHoverMenu.animation();
  },
};
</script>