import { preloadImages } from './utils';
import { Grid } from './grid';

var alternateColumnScroll = {
    animation(){
        // Preload images then remove loader (loading class) from body
        preloadImages('.column__item-img').then(() => {
            document.body.classList.remove('alternateColumnScroll__loading');

            // Initialize the grid
            // console.log("Initialize the grid")
            // console.log(document.querySelector('.columns'))

            new Grid(document.querySelector('.columns'));
        });
    }
}

export default alternateColumnScroll
