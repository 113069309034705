// import Cursor from './cursor';
import {preloader} from './preloader';
// import LocomotiveScroll from 'locomotive-scroll';
import Menu from './menu';


var imageHoverMenu = {
    animation(){ // identificateur
        // menu (<nav> element)
        const menuEl = document.querySelector('.imageHoverMenu--menu');


        // preload the images set as data attrs in the menu items
        preloader('.imageHoverMenu--menu__item').then(() => {
            // initialize the smooth scroll
            //const scroll = new LocomotiveScroll({el: menuEl, smooth: true});

            // initialize custom cursor
            // const cursor = new Cursor(document.querySelector('.cursor'));

            // initialize menu
            new Menu(menuEl);
        });
    }

}

export default imageHoverMenu