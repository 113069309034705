<style scoped>
    @import '../assets/css/projects.scss';
</style>
<template>
    <div id="projects__container">
        <AlternateColumnScroll/>
        <ImageHoverMenu/>
    </div>
</template>

<script scoped>
    import sourceData from '@/data/home_preview_projects.json'
    import AlternateColumnScroll from '@/components/scrolling-animation/AlternateColumnScroll.vue'
    import ImageHoverMenu from '@/components/mobile-tablet/ImageHoverMenu.vue'

    export default ({
        name: "Projects",
        components: {
            AlternateColumnScroll,
            ImageHoverMenu
        },
        computed:{
            projects(){
                    return sourceData.projects
            }
        },
        mounted: function (){
            // Show all the page content during the transition
            document.body.style.opacity = 1
            // lazyLoadingVanilla.load()
            // alternateColumnScroll.animation();
        },
        updated: function(){
            //alternateColumnScroll.animation();

        },
        methods: {
            // changeMenuImageOnHover(id) {
            //     var tl4 = gsap.timeline();
            //     // Hover and Click Animation Image Menu
            //     // console.log("button menu id hover =", id);

            //     // Get the id of the current image = currently in the last position
            //     var current_image_id = $('.projects__menu-image').children().last().attr('id');
            //     // console.log("last children id =", current_image_id);

            //     // Change the current image only if it is not already loaded
            //     if(current_image_id != id.replace("button","image")) {
            //         // Change the current selected image position to last position
            //         $('.projects__menu-image').append($('#' + id.replace("button","image")));
            //         // console.log("id ===",  id);
            //         // console.log("test ===", $('#' + id.replace("button","image")));

            //         // Before each displaying set the default clipPath --  origin to the top -- non visible
            //         tl4.set($("#" + id.replace("button","image")), {clipPath:"polygon(0%  0% , 100% 0% , 100% 0% , 0%  0%)"}); // origin to the top

            //         // Displaying the selected image with clipPath -- to origin full imag
            //         tl4.to($("#" + id.replace("button","image")), .8, {clipPath:"polygon(0 0, 100% 0%, 100% 100%, 0% 100%)"}); // to origin full image
            //     }
            // },
            getImgUrlImageProjects: function (projectName, imagePath) {
                return require('@/assets/img/projects/' + projectName + '/' + imagePath);
          },
        },
    })
</script>