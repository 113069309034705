<style scoped>
    @import "../../assets/css/scrolling-animation/alternateColumnScroll.scss";
</style>
<template>
  <div
    class="alternateColumnScroll__container
     alternateColumnScroll__loading
    "
  >
    <main class="alternateColumnScroll__main">
      <!--------- Titles  ------------->
      <h2 class="heading heading--up">{{data.slogan}}</h2>
      <h2 class="heading heading--down">{{data.slogan}}</h2>

      <div class="columns" data-scroll-container>
        <!-- First Columns -->
        <div class="column-wrap column-wrap--height">
          <div class="column">
            <figure v-for="project in data.figureElementsList1"
                    :id="project.id"
                    :key="project.id"
                    class="column__item">
              <div class="column__item-imgwrap" :data-pos="project.dataPos">
                <div
                  class="column__item-img"
                  :style="{'background-image': 'url(' + require('@/assets/img/projects/'+ project.slug + '/' + project.imgUrl) + ')'}"
                ></div>
              </div>
              <figcaption class="column__item-caption">
                <span class="column__item-caption--title">{{project.figcaptionTitle}}</span>
                <span class="column__item-caption--date">{{project.figcaptionDate}}</span>
              </figcaption>
            </figure>
          </div>
          <!-- /column -->
        </div>
        <!-- /column-wrap -->
        <!-- Second Column -->
        <div class="column-wrap">
          <div class="column" data-scroll-section>
              <figure v-for="project in data.figureElementsList2"
                    :id="project.id"
                    :key="project.id"
                    class="column__item">
              <div class="column__item-imgwrap" :data-pos="project.dataPos">
                <div
                  class="column__item-img"
                  :style="{'background-image': 'url(' + require('@/assets/img/projects/'+ project.slug + '/' + project.imgUrl) + ')'}"
                ></div>
              </div>
              <figcaption class="column__item-caption">
                <span class="column__item-caption--title">{{project.figcaptionTitle}}</span>
                <span class="column__item-caption--date">{{project.figcaptionDate}}</span>
              </figcaption>
            </figure>
          </div>
          <!-- /column -->
        </div>
        <!-- Third Column -->
        <div class="column-wrap column-wrap--height">
          <div class="column">
            <figure v-for="project in data.figureElementsList3"
                    :id="project.id"
                    :key="project.id"
                    class="column__item">
              <div class="column__item-imgwrap" :data-pos="project.dataPos">
                <div
                  class="column__item-img"
                  :style="{'background-image': 'url(' + require('@/assets/img/projects/'+ project.slug + '/' + project.imgUrl) + ')'}"
                ></div>
              </div>
              <figcaption class="column__item-caption">
                <span class="column__item-caption--title">{{project.figcaptionTitle}}</span>
                <span class="column__item-caption--date">{{project.figcaptionDate}}</span>
              </figcaption>
            </figure>
          </div>
          <!-- /column -->
        </div>
        <!-- /column-wrap -->
      </div>
      <!-- columns -->
      <!-- Details vue - description -->
      <div class="content">
        <div  v-for="project in data.figureElementsList1"
              :id="project.id"
              :key="project.id"
              class="content__item">
          <h2 :id="`content__item-title--${project.id}`"
             class="content__item-title">
            {{project.figcaptionTitle.replace("-"," ")}}
          </h2>
          <div class="content__item-text">
            <span class="content__item-text--description">{{project.description}}</span>
            <span class="content__item-text--figcaptionDate">{{project.figcaptionDate}}</span>
            <router-link
                class="alternateColumnScroll__router-link"
                :to="{ name:'project.show',
                                params:{
                                slug: project.slug}
                                }">
                <span>View project</span>
            </router-link>
          </div>
        </div>
        <div  v-for="project in data.figureElementsList2"
              :id="project.id"
              :key="project.id"
              class="content__item">
          <h2 class="content__item-title">{{project.figcaptionTitle.replace("-"," ")}}</h2>
          <div class="content__item-text">
            <span class="content__item-text--description">{{project.description}}</span>
            <span class="content__item-text--figcaptionDate">{{project.figcaptionDate}}</span>
            <router-link
                class="alternateColumnScroll__router-link"
                :to="{ name:'project.show',
                                params:{
                                slug: project.slug}
                                }">
                <span>View project</span>
            </router-link>
          </div>
        </div>
        <div  v-for="project in data.figureElementsList3"
              :id="project.id"
              :key="project.id"
              class="content__item">
          <h2 class="content__item-title">{{project.figcaptionTitle.replace("-"," ")}}</h2>
          <div class="content__item-text">
            <span class="content__item-text--description">{{project.description}}</span>
            <span class="content__item-text--figcaptionDate">{{project.figcaptionDate}}</span>
            <router-link 
                 class="alternateColumnScroll__router-link"
                 :to="{ name:'project.show',
                                params:{
                                slug: project.slug}
                                }">
                <span>View project</span>
            </router-link>
          </div>
        </div>

        <!-- Thumbnails -->
        <nav class="content__nav">
          <div class="content__nav-wrap">
            <div v-for="project in data.figureElementsList1"
                 :id="project.id"
                 :key="project.id"
                 class="content__nav-item"
                 :style="{'background-image': 'url(' + require('@/assets/img/projects/' + project.slug + '/' + project.imgUrl) + ')'}"
            ></div>
            <div v-for="project in data.figureElementsList2"
                 :id="project.id"
                 :key="project.id"
                 class="content__nav-item"
                 :style="{'background-image': 'url(' + require('@/assets/img/projects/' + project.slug + '/' + project.imgUrl) + ')'}"
            ></div>
            <div v-for="project in data.figureElementsList3"
                 :id="project.id"
                 :key="project.id"
                 class="content__nav-item"
                 :style="{'background-image': 'url(' + require('@/assets/img/projects/' + project.slug + '/' + project.imgUrl) + ')'}"
            ></div>
          </div>
        </nav>
        <button class="unbutton button-back">
          <svg viewBox="0 0 50 9" width="100%">
            <path d="M0 4.5l5-3M0 4.5l5 3M50 4.5h-77"></path>
          </svg>
          <!-- apps_grid_icon.svg-->
          <!-- <svg height="60px" version="1.1" viewBox="0 0 16 16" 
               width="60px" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title />
              <desc />
              <defs />
              <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
                  <g fill="	#707070" id="Core" transform="translate(-340.000000, -4.000000)">
                      <g id="apps" transform="translate(340.000000, 4.000000)">
                          <path d="M0,4 L4,4 L4,0 L0,0 L0,4 L0,4 Z M6,16 L10,16 L10,12 L6,12 L6,16 L6,16 Z M0,16 L4,16 L4,12 L0,12 L0,16 L0,16 Z M0,10 L4,10 L4,6 L0,6 L0,10 L0,10 Z M6,10 L10,10 L10,6 L6,6 L6,10 L6,10 Z M12,0 L12,4 L16,4 L16,0 L12,0 L12,0 Z M6,4 L10,4 L10,0 L6,0 L6,4 L6,4 Z M12,10 L16,10 L16,6 L12,6 L12,10 L12,10 Z M12,16 L16,16 L16,12 L12,12 L12,16 L12,16 Z" id="Shape" />
                      </g>
                  </g>
              </g>
          </svg>   -->
          <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="50px">
            <text x="10" y="20">Back</text>
          </svg>
        </button>
      </div>
    </main>
  </div>
</template>
<script scoped>
import alternateColumnScroll from "@/assets/js/scrolling-animation/alternateColumnScroll/index.js";
import sourceData from "@/data/alternate_column_scroll.json";
// import gsap from 'gsap';

export default {
  name: "AlternateColumnScroll",
  // data() {
  // },
  components: {},
  // On définit la prop id de type string et obligatoire pour le fonctionnement de la route
  props: {},
  computed: {
    data() {
      // console.log(sourceData.dataPage);
      return sourceData.dataPage;
    },
  },
  methods: {
    // getImgUrl: function (imagePath) {
    //   return require("@/assets/img/home/" + imagePath);
    // },
    // https://vuejs.org/guide/essentials/event-handling.html#method-handlers
    // test() {
    //     console.log("tessst")

    // },
  },
  mounted: function () {
    // une fois le rendu de la vue entière terminé
    alternateColumnScroll.animation();
    // console.log("MOUNTED alternate")

    // DOCUMENTATION: remove multiple classes from an element
    // https://stackoverflow.com/questions/11115998/is-there-a-way-to-add-remove-several-classes-in-one-single-instruction-with-clas
    var body = document.body;
    body.classList.remove(...["oh","view-content"]);


    // Heading animations entrances

    // var tl = gsap.timeline();
    // tl.to([".heading"], {duration: 2, opacity:1});
    // tl.add([".heading"], {className: "alternateColumnScrollHeading__Text_Animate"});
  },
  updated: function(){
    // alternateColumnScroll.animation();
  },
  unmounted: function () {
    // alternateColumnScroll.animation();
  },
};
</script>


